@import '../../assets/scss/styles';

.root {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 70px);
}

.aside {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 33.333%;
  min-width: 476px;
  padding: 40px;

  position: fixed;
  left: 0;
  height: 100vh;
  overflow: auto;

  background-color: #f9f9f9;
  border-radius: 24px;

  @media screen and (max-width: 1150px) {
    align-items: center;
    width: 50%;
    min-width: auto;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 396px;
    width: 100%;
  }
  &__logo {
    width: 100px;
    height: 100px;
    margin-bottom: 40px;
  }
}
