.ant-menu {
  &-item {
    display: flex;
    align-items: center;

    color: #adadad;
    svg {
      width: 17px;
      height: 17px;

      fill: #adadad;
    }

    a {
      color: #adadad;
    }

    &-selected {
      height: 40px;

      color: @primary-color;
      line-height: 40px;

      background-color: #f9f9f9;
      // Select
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;

      svg {
        height: 17px;
        width: 17px;

        fill: @primary-color;
      }
    }
  }
}
