@text-color: #111;
@box-shadow-base: 2px 8px 8px 0 rgba(255, 134, 99, .16), 0 0 4px 0 rgba(255, 134, 99, .16);
@box-shadow-hover: 2px 12px 16px 0 rgba(255, 134, 99, .32), 0 0 4px 0 rgba(255, 134, 99, .16);

.ant-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;

  font-weight: 600;
  line-height: 1.5;


  &-sm {
    height: 24px;
    padding: 0 7px;

    font-size: 13px;
  }

  &-default {
    color: @primary-color;

    border: 1px solid @primary-color;
    border-radius: 24px;
  }

  &-primary {
    border-radius: 24px;
    color: #fff;

    background-color: @primary-color;
    border: none;
    box-shadow: @box-shadow-base;

    &:hover {
      color: #fff;

      background-color: @primary-color;
      box-shadow: @box-shadow-hover;
    }
  }
}
