.mixin-input() {
  width: 100%;
  height: 48px;
  padding: 3.2px 16px;

  background-color: #fff;
  border: none;
  border-radius: 24px;
  box-shadow: 2px 8px 24px 0 rgba(0, 0, 0, 0.04), 0 0 4px 0 rgba(0, 0, 0, 0.04);
}

.mixin-input-placeholder() {
  color: #a0a0a0;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.33;
  font-style: normal;
  font-stretch: normal;
}