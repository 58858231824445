$primary-color: #ff8663;
$link-color: #000;
$success-color: #52c41a; // success state color
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color
$font-size-base: 15px; // major text font size
$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: #111111; // major text color
$text-color-secondary: #000000; // secondary text color
$disabled-color: #A1A1A1; // disable state color
$border-radius-base: 24px; // major border radius
$border-color-base: #A1A1A1; // major border color
$box-shadow-base: 2px 8px 8px 0 rgba(208, 215, 221, 0.16), 0 0 4px 0 rgba(208, 215, 221, 0.18);

$body-bg: #fff;
// $title-color: #152b51;

$font-default: Arial, sans-serif;
$font-family-base: 'Avenir Next Cyr', $font-default;
$font-family-regular: 'AvenirNextCyr-Regular', $font-default;
$font-family-buttons: 'Avenir Next Cyr Demi', $font-default;

$padding-page: 32px 16px;

/**
  * FONTS-SIZE
  */
$font-size-base: 15px;

$h1-font-size: 32px;
$h2-font-size: 25px;
$h3-font-size: 16px;

$small-text: 13px;

$h1-font-size-xs: 2rem;
$h2-font-size-xs: 1.625rem;
$h3-font-size-xs: 1.125rem;

