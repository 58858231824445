.ant-drawer {
  &-content {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  &-body {
    background: #f9f9f9;
    border-radius: 16px;
  }
  &-close {
    top: 8px;
    right: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0;

    background: rgba(246, 71, 71, .1);
    border-radius: 100%;
    box-shadow: @box-shadow-base;
    span {
      width: 16px;
      height: 16px;

      color: #f64747;
    }
  }
}