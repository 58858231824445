

.datepicker {
    position: relative;


    .picker {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .ant-picker-input {
            input {
                opacity: 0;
            }
        }
    }

    .input {
        position: relative;
        z-index: 1;
        width: 160px;
        box-shadow: none;
        outline: none!important;
        border: none;
        background: none;
        height: 48px;
        padding-left: 20px;
    }
}