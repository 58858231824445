.ant-form {
  &-item {
    &-label {
      padding-bottom: 0;

      color: #888;
      font-family: @font-family-regular;
      font-size: 15px;
      line-height: 1.5;
    }
    &-explain {
      font-size: @small-text;
    }
  }
}