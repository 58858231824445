.ant-modal {
  &-header {
    border-radius: 16px 16px 0 0;
  }
  &-content {
    border-radius: 16px;
  }

  .ant-btn {
    height: 36px;
  }
}