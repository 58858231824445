.ant-table {
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgba(208, 215, 221, .18);
  table {
    border-radius: 16px !important;
  }
  &-thead {
    & > tr > th {
      color: #414141 ;
      font-family: @font-family-regular;
      font-size: @small-text;

      border-bottom: 2px solid #ffe7e0;
    }
  }

  &-container {
    table > thead > tr:first-child {
      th:first-child {
        border-top-left-radius: 16px;
      }
      th:last-child {
        border-top-right-radius: 16px;
      }
    }
  }

  &-tbody {
    color: #292929;
    font-family: @font-family-base;
    font-size: 15px;
    line-height: 1.5;
    & > tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
      & > td {
        border-bottom: 2px solid #fff5f3;
      }
    }
  }
}

.ant-pagination {
  &-item {
    background-color: #e6e6e6;
    border: none;
    border-radius: 4px;

    &-link {
      background-color: #e6e6e6 !important;
      border: none !important;
      border-radius: 4px !important;
    }
  }
}
