html {
  box-sizing: border-box;

  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;

  min-width: 320px;
  height: 100%;
  overflow-x: hidden;

  color: @text-color;
  font-family: @font-family-base;
  line-height: 1.5;

  background: @body-bg;
  opacity: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  font-family: @font-family-base;
}

h1,
.h1 {
  font-size: @h1-font-size;
}
h2,
.h2 {
  font-size: @h2-font-size;
}
h3,
.h3 {
  font-size: @h3-font-size;
}

blockquote {
  margin-bottom: 0;
}

p {
  font-family: @font-family-base;
}

a {
  font-family: @font-family-base;
  font-weight: 600;
  // font-size: 15px;
  text-decoration: none;
  letter-spacing: normal;

  transition: color 0.3s ease;
  &:hover {
    // color: #1273e6;
    text-decoration: none;
    font-style: normal;
  }
}

b,
strong {
  color: #2c314e;
  font-family: @font-family-base;
  font-weight: 700;
}

#root {
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}

.text-copy {
  color: rgba(0, 0, 0, 0.25);
}
