@import '../../../node_modules/normalize.css/normalize.css';
@import '../../../node_modules/antd/dist/antd.less';
@import './_fonts';
@import './_variables';
@import './_mixins';

@import './_global';
@import './components/index';

h1.ant-typography, .ant-typography h3 {
  color: @text-color;
}
h2.ant-typography, .ant-typography h2 {
  color: @text-color;
}
h3.ant-typography, .ant-typography h3 {
  color: @text-color;
}
h4.ant-typography, .ant-typography h4 {
  margin-bottom: 0;
}

.isDisabled {
  color: currentColor;
  text-decoration: none;

  cursor: not-allowed;
  opacity: .5;
}

.title {
  font-weight: bold;
}

.page {
  padding: @padding-page;
}

.disable-scroll {
  position: fixed !important;

  overflow: hidden !important;
}


.media-carusel {
  // .slick-list {
  //     max-height: 75vh;
  //     img {
  //       max-height: 75vh;
  //     }
  // }
  top: 20px
}


.ant-drawer-inline{
  position: fixed!important;
}

.confirm-terms-modal {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 48px;

  }

  .ant-modal-body{ 
    @media screen and (max-width: 500px) {
     padding-bottom: 10px;
     padding-top: 40px;
    }
  }
  .title { 
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    margin-bottom: 0;
    padding: 20px 30px 0;
    @media screen and (max-width: 500px) {
      font-size: 18px;
      padding: 0;
    }
  }
  .ant-btn {
    width: 190px;
    height: 48px;

    @media screen and (max-width: 500px) {
      width: 100px;
    }
  }


}


