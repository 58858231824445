.ant-upload {
  &-btn {
    padding: 24px 0 !important;
  }

  &-list {
    &-item {
      &-name {
        color: #111;
        font-family: @font-family-base;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
      }

      &-thumbnail {
        padding-top: 0;
        //padding-top: 10px;
      }
    }

    &-picture {
      &-card {
        &-container {
          width: 125px;
          height: 125px;

          .ant-upload-list-item-name {
            bottom: 0 !important;

            height: 34px;

            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
          }
        }

        .ant-upload-list-item {
          width: 125px;
          height: 125px;
          padding: 0;
          overflow: auto;

          border-radius: 16px;

          &-thumbnail {
            svg {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }

  &.ant-upload-select {
      display: flex;
      justify-content: center;
  }

  &.ant-upload-drag {
    border-radius: 16px;
  }


  &.ant-upload-select-picture-card {
    width: 125px;
    height: 125px;

    border-radius: 16px;
  }
}

.account-setting,.main-info {
  .ant-upload {
    &.ant-upload-select-picture-card {
      width: 192px;
      height: 192px;

      border-radius: 50%;
    }
  }
}
