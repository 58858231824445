textarea.ant-input {
  padding: 12px 16px;
}

.ant-input {
  .mixin-input();

  &::placeholder {
    .mixin-input-placeholder();
  }

  &-number {
    width: 100%;

    background-color: #fff;
    border: none;
    border-radius: 24px;
    &-input {
      width: 100%;
      height: 48px;
      padding: 3.2px 16px;

      border-radius: 24px;
      box-shadow: 2px 8px 24px 0 rgba(0, 0, 0, 0.04),
        0 0 4px 0 rgba(0, 0, 0, 0.04);

      &::placeholder {
        .mixin-input-placeholder();
      }
    }
  }

  &-search {
    width: 300px;
    border-radius: 24px;
    box-shadow: 2px 8px 24px 0 rgba(0, 0, 0, 0.04),
      0 0 4px 0 rgba(0, 0, 0, 0.04);

    .ant-input-group-addon {
      border: none;
      border-radius: 0 50% 50% 0;
    }

    &
      .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      border-radius: 0 50% 50% 0;
    }

    &-button {
      border: none;
      background-color: #eef2fe;
    }
  }
}
