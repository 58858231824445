.ant-select {
  &::placeholder {
    .mixin-input-placeholder();
  }

  &-single {
    &:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: 48px;
    }

    .ant-select-selector .ant-select-selection-search {
      left: 22px;
    }
  }

  &.ant-select-auto-complete {
    .ant-select-selector {
      padding: 0 !important;

      background: transparent;
      border-radius: 24px;
    }
  }

  &-selector {
    align-items: center;
    min-height: 48px;
    padding: 5px 24px !important;

    border: 0 !important;
    border-radius: 24px !important;
    box-shadow: 2px 8px 24px 0 rgba(0, 0, 0, 0.04), 0 0 4px 0 rgba(0, 0, 0, 0.04);
  }
  &-selection {
    &-search {
      //top: 10px;
      //right: 22px;
    }
    &-placeholder {
      left: 30px !important;
    }
  }

  &-arrow {
    width: 16px;
  }

  &-multiple {
    .ant-select-selection-item {
      height: 32px;
      padding: 4px 4px 4px 8px;

      color: #12152e;

      background-color: #d8d8d8;
      border-radius: 16px;

      &-content {
        font-size: 13px;
        text-align: center;
      }
    }
  }
}